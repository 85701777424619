
/*Agent Dashboard css starts heres*/


#property-dashboard h3 {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 0em;
    margin-bottom: 0.9em;
    padding-top: 0.8em;
}

@media (max-width: 480px){
    .checkbox-row {
      padding-left: 0px;
    }
    #property-dashboard .form-control {
        font-size: 14px !important;
     }
    #property-dashboard .form-group label {
        font-size: 14px !important;
        }
        .checkbox-row .col-lg-3 {
        padding-left: 0px;
    }
    #property-dashboard .agent-checkbox {
        display: block;
        position: relative;
        padding-left: 30px;
    }
    .checkmark {
        height: 15px;
        width: 15px;
    }
    .agent-checkbox .checkmark:after {
        left: 4px;
        top: 2px;
        width: 5px;
        height: 8px;
    }
}
/*Agent Dashboard css starts heres*/
#property-dashboard {
    background: #f0f0f0;
}
#property-dashboard .form-control {
    font-size: 16px;
    font-weight: 500;
}

#property-dashboard h2 {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
   padding-bottom: 0.4em;
}

#property-dashboard .form-content {
  border-radius: 0px;
  padding-top: 1em;
  padding-bottom: 1em;
}

#property-dashboard .form-group label {
    display: table;
    font-size: 15px;
    font-weight: 500;
    color: #131313;
    margin-bottom: 0.4em;
}

.agent-checkbox {
    margin-bottom: 0.7em !important;
}

#property-dashboard .agent-btn {
border-radius: 0px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
}

#property-dashboard .agent-btn:hover {
  color: #fff;
}

/*checkbox css starts heres*/
/* The container */
.agent-checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.agent-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.agent-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.agent-checkbox input:checked ~ .checkmark {
  background-color: #ff4869;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.agent-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.agent-checkbox .checkmark:after {
  left: 5px;
    top: 3px;
    width: 6px;
    height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.agent-checkbox .checkmark {
    top: 2px;
}

 .checkbox-row {
      padding: 0 1em 0;

}

/*Agent Dashboard css ends heres*/



/***************** Header dropdown ************/

.nav-dropdown-btn {
  background: var(--pink) !important;
  color: #fff !important;
  border-radius: 3px;
  font-weight: 400;
  position: relative;
  border: none;
}
.nav-dropdown .dropdown-menu {
padding: 0.5em;
}

.nav-dropdown .dropdown-menu li a {
color: #000;
font-size: 0.9em;
line-height: 2.1;
}
.nav-dropdown .dropdown-menu {
  margin-left: -2.4em;
  margin-top: 0.3em;
}
.dropdown-menu li a { 
text-decoration: none;
}