

.infowindow {
    position: absolute;
    left: -45px;
    width: 220px;
    background-color: white;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    padding: 10px;
    font-size: 14px;
    z-index: 100;
    border: 1px solid;
    border-radius: 2px;
}