.profile-main .box {
    padding: 30px 55px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 0 9px 3px rgb(0 0 0 / 7%);
    margin: 0 0 25px;
  }
  
  .profile-main .box h4 {
    margin: 0 0 20px;
    font-weight: 600;
    text-transform: capitalize;
    font-size: 18px;
  }
  
  .profile-main .box h4 span {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 15px;
    text-decoration: underline;
  }
  
  .profile-main .box h4 span a {
    color: #3b424f;
  }
  
  .profile-main .box.details ul li {
    font-size: 14px;
    margin: 0 0 10px;
    color: #272727;
    font-weight: 700;
  }
  
  .profile-main .box.details ul li:last-child {
    margin: 0;
  }
  
  .profile-main .box.details ul {
    width: 80%;
  }
  
  .profile-main .box.details ul li span {
    width: 50%;
    display: inline-block;
    color: #737d83;
  }
  
  .profile-main .box.details ul li p {
    margin: 0;
    width: 49%;
    display: inline-block;
  }
  
  .profile-main .box table td,.profile-main .box table th {
    font-size: 14px;
  }
  
  .profile-main .box table th {
    border-top: 0;
    border-bottom: 0;
  }
  
  .profile-main .box .refer-box {}
  
  .profile-main .box .refer-box label {
      margin: 0;
      color: #2e2e43;
      font-size: 16px;
  }
  
  .profile-main .box .refer-box p {
      margin: 3px 0 15px;
      font-size: 15px;
      color: #737d83;
  }
  
  .profile-main .box .refer-box input.form-control {
      height: 45px;
      border-radius: 4px;
      color: #737d83;
      font-size: 15px;
      padding: 0 15px;
      margin: 0 0 20px;
      border-color: #cfcfcf;
  }
  
  .profile-main .box .refer-box button.btn {
      background: var(--pink) !important;
      color: #fff !important;
      border-radius: 4px;
      text-transform: uppercase;
      font-size: 15px;
      height: 45px;
      padding: 0 25px;
      font-weight: 600;
  }
  
  .profile-main .box .refer-box ul {
      display: flex;
  }
  
  .profile-main .box .refer-box ul li {
      margin-right: 12px;
  }
  
  .profile-main .box .refer-box ul li a {
      display: block;
      border-radius: 8px;
      overflow: hidden;
  }
  
  .profile-main .box .refer-box ul li a img {
      width: 45px;
  }
  
  .profile-main .box.notification li .btn {
    background: #3b5998;
    color: #fff;
    text-transform: capitalize;
    font-size: 15px;
    margin-right: 15px;
    align-items: center;
    display: inline-block;
    border-radius: 5px;
  }
  
  .profile-main .box.notification li {
    font-size: 15px;
    display: flex;
    align-items: center;
    padding: 15px 0;
    color: #4d4848;
    border-bottom: 1px solid #dee2e6;
  }
  
  .profile-main .box.notification li .btn img {
    width: 16px;
    margin-right: 12px;
    position: relative;
    top: -2px;
  }
  
  .profile-main .box.notification li:last-child {
    border: none;
  }
  
  .profile-main .box.payment {}
  
  .profile-main .box.payment .option-box {
      border-radius: 4px;
      border: 1px solid #dee2e6;
      width: 60%;
      margin: 0 0 25px;
      padding: 15px;
  }
  
  .profile-main .box.payment .option-box label {
      width: 100%;
      text-transform: capitalize;
      color: #000000;
      font-weight: 800;
      margin: 0;
      position: relative;
  }
  
  .profile-main .box.payment .option-box label .card-detail {
      display: none;
  }
  
  .profile-main .box.payment .option-box label input[type="radio"]:checked ~ .card-detail {
      display: block;
      margin-top: 10px;
      padding: 15px;
      background: #eeeff4;
      border-radius: 4px;
      width: 85%;
  }
  
  .profile-main .box.payment .option-box .card-detail ul li {
      font-size: 14px;
      font-weight: normal;
      margin: 0 0 10px;
  }
  
  .profile-main .box.payment .option-box .card-detail ul li span {
      width: 50%;
      display: inline-block;
      text-transform: uppercase;
      color: #9b9ca1;
  }
  
  .profile-main .box.payment .option-box .card-detail ul li:last-child {
      margin: 0;
  }
  
  .profile-main .box.payment .option-box .card-detail ul li input {
      border-radius: 4px;
      height: 35px;
      border: 1px solid #b9b9b9;
      padding: 0 5px;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0
  }
  
  / Firefox /
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .profile-main .box.payment .option-box label span.check {
      border: 2px solid #f00;
      width: 18px;
      height: 18px;
      display: inline-block;
      border-radius: 50px;
      top: 3px;
      position: relative;
      margin-right: 5px;
      cursor: pointer;
  }
  
  .profile-main .box.payment .option-box label input[type="radio"] {
      position: absolute;
      width: 18px;
      height: 20px;
      top: 3px;
      opacity: 0;
      cursor: pointer;
  }
  
  .profile-main .box.payment .option-box label input[type="radio"]:checked + span.check:before {content: '';position: absolute;width: 10px;height: 10px;background: #f00;border-radius: 50px;top: 2px;left: 2px;}
  
  
  
  @media (max-width: 567px) {
  
  .profile-main .box{
    padding: 20px 15px;
  }
  
  .profile-main .box.details ul,.profile-main .box.payment .option-box{
    width: 100%;
  }
  .profile-main .box.notification li .btn {
    min-width: 130px;
  }
  
  .scroll-table {
    overflow-x: scroll;
    white-space: nowrap;
  }
}